<template>
    <div class="inscription container-fluid mx-auto d-flex-center flex-column my-4 flex-fill body-container">
        <!-- <img src="../assets/img/fond.png" class="img-fond" alt="" /> -->

        <div v-if="isLoading" class="loader d-flex-center">
            <div class="d-flex-center flex-column bg-secondary bg-opacity-50 rounded p-3 pt-4--5">
                <!-- <div class="spinner-border text-primary-dark" role="status"></div> -->
                <i class="fa-solid fa-glass-water text-primary-dark fa-bounce fa-2xl"></i>
                <strong class="text-primary-dark mt-3">Envoi du formulaire en cours...</strong>
            </div>
        </div>

        <img src="../assets/img/logo.svg" class="inscription__logo mb-4" alt="" />

        <div class="bg-white p-3 shadow bg-opacity-100 rounded">
            <h1 class="w-100 bg-secondary rounded mb-3 py-3">Formulaire de réponse</h1>

            <form method="POST" class="inscription__form needs-validation" novalidate>
                <div class="row g-3">
                    <div class="col-12 d-flex-center py-3">
                        <span class="fs-5">Je souhaite participer à l'événement :</span>
                        <button
                            type="button"
                            class="btn btn-lg mx-3"
                            v-bind:class="[form_data.is_participating ? 'btn-primary' : 'btn-outline-primary']"
                            @click="participate(true)"
                            for="chk_yes"
                        >
                            Oui
                        </button>
                        <button
                            type="button"
                            class="btn btn-lg"
                            v-bind:class="[form_data.is_participating ? 'btn-outline-secondary' : 'btn-secondary']"
                            @click="participate(false)"
                            for="chk_no"
                        >
                            Non
                        </button>
                    </div>
                    <div class="col-12 col-sm-6">
                        <div class="form-floating">
                            <input
                                class="form-control text-uppercase"
                                type="text"
                                v-model="form_data.last_name"
                                id="last_name"
                                name="last_name"
                                placeholder="Dupond"
                                required
                            />
                            <label for="last_name">Nom*</label>
                            <div class="invalid-feedback">Veuillez renseigner votre nom</div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <div class="form-floating">
                            <input
                                class="form-control text-capitalize"
                                type="text"
                                v-model="form_data.first_name"
                                id="first_name"
                                name="first_name"
                                placeholder="Alain"
                                required
                            />
                            <label for="first_name">Prénom*</label>
                            <div class="invalid-feedback">Veuillez renseigner votre prénom</div>
                        </div>
                    </div>

                    <div class="col-12 col-sm-7">
                        <div class="form-floating">
                            <input
                                class="form-control"
                                type="email"
                                v-model="form_data.email"
                                id="email_address"
                                name="email_address"
                                placeholder="nom@exemple.fr"
                                required
                            />
                            <label for="email_address">Adresse email*</label>
                            <div class="invalid-feedback">Adresse email invalide</div>
                        </div>
                    </div>

                    <div class="col-12 col-sm-5">
                        <div class="form-floating">
                            <input
                                class="form-control"
                                type="tel"
                                v-model="form_data.phone_number"
                                id="phone_number"
                                name="phone_number"
                                placeholder="0701020304"
                                required
                            />
                            <label for="phone_number">Numéro de téléphone*</label>
                            <div class="invalid-feedback">Veuillez renseigner votre numéro de téléphone</div>
                        </div>
                    </div>

                    <div class="col-12 ps-2" v-if="form_data.is_participating">
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                v-model="form_data.is_accompanied"
                                type="checkbox"
                                id="is_accompanied"
                            />
                            <label class="form-check-label" for="is_accompanied">Je souhaite venir accompagné(e)</label>
                        </div>
                    </div>

                    <div
                        v-if="form_data.is_accompanied && form_data.is_participating"
                        class="col-12 small text-primary text-uppercase"
                        style="letter-spacing: 1px"
                    >
                        Accompagnant(e)
                    </div>
                    <div v-if="form_data.is_accompanied && form_data.is_participating" class="col-12 col-sm-6">
                        <div class="form-floating">
                            <input
                                class="form-control text-uppercase"
                                type="text"
                                v-model="form_data.mate_last_name"
                                id="mate_last_name"
                                name="mate_last_name"
                                placeholder="Dupont"
                                required
                            />
                            <label for="mate_name">Nom accompagnant(e)*</label>
                        </div>
                    </div>
                    <div v-if="form_data.is_accompanied && form_data.is_participating" class="col-12 col-sm-6">
                        <div class="form-floating">
                            <input
                                class="form-control text-capitalize"
                                type="text"
                                v-model="form_data.mate_first_name"
                                id="mate_first_name"
                                name="mate_first_name"
                                placeholder="Alain"
                                required
                            />
                            <label for="mate_name">Prénom accompagnant(e)*</label>
                        </div>
                    </div>
                </div>

                <button @click="register" class="w-100 btn btn-primary btn-lg mt-3" type="button">Valider</button>
                <div class="mt-2 small">* champs obligatoires</div>
            </form>
        </div>
    </div>
</template>

<script>
import router from "../plugins/router";
import { functions } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";
const NiceDAB_SaveRegistration = httpsCallable(functions, "NiceDAB_SaveRegistration");

const empty_form = {
    is_participating: true,
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    is_accompanied: false,
    mate_first_name: "",
    mate_last_name: "",
};

export default {
    created() {
        this.cleanData();
    },
    data() {
        return {
            isLoading: false,
            hasError: false,
            form_data: {
                is_participating: true,
                last_name: "Modin",
                first_name: "Damien",
                email: "damien@innov-events.fr",
                phone_number: "0789215568",
                is_accompanied: true,
                mate_first_name: "Jacques",
                mate_last_name: "Onpagne",
            },
        };
    },
    props: {},
    methods: {
        participate(is_participating) {
            this.form_data.is_participating = is_participating;
        },
        async register() {
            if (!this.isLoading) {
                this.isLoading = true;
                const form = document.querySelector(".needs-validation");
                form.classList.add("was-validated");

                if (form.checkValidity()) {
                    await NiceDAB_SaveRegistration(this.form_data)
                        .then((result) => {
                            const data = result.data;
                            if (data) {
                                localStorage.p = this.form_data.is_participating;
                                this.cleanData();
                                router.push({ name: "done" });
                            } else {
                                this.$data.hasError = true;
                                form.classList.remove("was-validated");
                                window.scrollTo(0, 0);
                            }
                        })
                        .catch((error) => {
                            const code = error.code;
                            const message = error.message;
                            const details = error.details;
                            console.log(code, message, details);
                            this.$data.hasError = true;
                            form.classList.remove("was-validated");
                            window.scrollTo(0, 0);
                        });
                }

                this.isLoading = false;
            }
        },
        cleanData() {
            this.form_data = Object.assign({}, empty_form);
            const form = document.querySelector(".needs-validation");
            form.classList.remove("was-validated");
        },
    },
};
</script>

<style lang="scss" scoped>
$primary: #f1deab;
$secondary: #d2e3c8;
.loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba($color: $secondary, $alpha: 0.5);
}
</style>
